import "./css/style.css";

import "swiper/css";
import "swiper/css/pagination";

/*=============== COOKIES ===============*/
import { cookies } from "./modules/cookies";
function marketing() {
  console.log("Marketing function activated");
}
function analytics() {
  console.log("Analytics function activated");
}
cookies({ marketing, analytics});

/*=============== MODAL ===============*/
import { iniciaModal } from "./modules/modal";
document.addEventListener("scroll", () => {
  if (window.pageYOffset > 800) {
    iniciaModal("modal-promocao");
  }
});

/*=============== SHOW SCROLL UP ===============*/
import { scrollUp } from "./modules/scrollUp";
window.addEventListener("scroll", scrollUp);

/*=============== SCROLL REVEAL ANIMATION ===============*/
import { initScrollReveal } from "./modules/scrollReveal";
initScrollReveal();

/*=============== VÍDEO BANNER ===============*/
import { toggleMenu } from "./modules/menu";
toggleMenu();

/*=============== VÍDEO BANNER ===============*/
import { initSwiper } from "./modules/swiperInit";
initSwiper();

/*=============== VÍDEO BANNER ===============*/
import { videoBanner } from "./modules/videoBanner";
videoBanner();
