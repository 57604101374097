import Swiper, { Pagination, EffectCreative, Autoplay } from "swiper";

export function initSwiper() {
  new Swiper(".mySwiper", {
    grabCursor: true,
    loop: true,
    effect: "creative",
    modules: [Pagination, EffectCreative, Autoplay],
    creativeEffect: {
      prev: { shadow: true, translate: ["-120%", 0, -500] },
      next: { shadow: true, translate: ["120%", 0, -500] },
    },
    speed: 1000,
    fadeEffect: { crossFade: true },
    autoplay: { delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false },
    pagination: { el: ".swiper-pagination", dynamicBullets: true, clickable: true },
  });
}
