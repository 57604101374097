export function cookies(functions) {
  const container = document.querySelector(".cookies__container");
  const save = document.querySelector(".cookies__save");
  if (!container || !save) return null;

  const localPref = JSON.parse(window.localStorage.getItem("cookies-pref"));

  if (localPref) {
    activateFunctions(localPref);
    container.style.display = "none";
  }

  function getFormPref() {
    return [...document.querySelectorAll("[data-function]")]
      .filter((el) => el.checked)
      .map((el) => el.getAttribute("data-function"));
  }

  function activateFunctions(pref) {
    pref.forEach((f) => functions[f] && functions[f]());
    window.localStorage.setItem("cookies-pref", JSON.stringify(pref));
  }

  function handleSave() {
    const pref = getFormPref();
    activateFunctions(pref);
    container.style.display = "none";
  }

  save.addEventListener("click", handleSave);
}
