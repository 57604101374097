import ScrollReveal from "scrollreveal";

export function initScrollReveal() {
  const sr = ScrollReveal({
    origin: "top",
    distance: "60px",
    duration: 1200,
    delay: 400,
  });

  sr.reveal(`.animation`);
  sr.reveal(`.animation__left`, { origin: "left" });
  sr.reveal(`.animation__right`, { origin: "right" });
  sr.reveal(`.animation__interval`, { interval: 50 });
}
