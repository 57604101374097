export function videoBanner() {
  document.addEventListener("DOMContentLoaded", function () {
    const bannerVideo = document.getElementById("bannerVideo");
    const soundToggle = document.getElementById("soundToggle");
    const videoOverlay = document.getElementById("videoOverlay");

    if (bannerVideo && soundToggle && videoOverlay) {
      bannerVideo.addEventListener("canplay", () => {
        soundToggle.style.display = "block";
        videoOverlay.style.display = "block";
      });

      setTimeout(() => {
        if (bannerVideo.readyState >= 3) {
          soundToggle.style.display = "block";
          videoOverlay.style.display = "block";
        }
      }, 1000);

      videoOverlay.addEventListener("click", () => {
        if (bannerVideo.muted) {
          bannerVideo.muted = false;
          soundToggle.style.display = "none";
        } else {
          bannerVideo.muted = true;
          soundToggle.style.display = "block";
        }
      });
    }
  });
}
